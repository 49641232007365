import React, { useEffect, useRef, useState } from "react";
import * as echarts from "echarts";
import axios from "axios";
import { Spinner } from "@abb/abb-common-ux-react";

function RenewableEnergy() {
  const chartRef = useRef(null);

  const [isSpin, setispin] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchRenewableEnergyData();
    const interval = setInterval(() => {
      fetchRenewableEnergyData();
    }, 15 * 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  const fetchRenewableEnergyData = async () => {
    setispin(true);
    try {
      const response = await axios.get(
        "https://bzxhlhpxj7.execute-api.us-east-1.amazonaws.com/api/multisite_analysis",
        {
          "Content-Type": "application/json",
          Connection: "keep-alive",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods":
            "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Accept-Language": "en-US",
        }
      );
      setData(response.data);
      setispin(false);
      setError(false);
    } catch (error) {
      setError(true);
      setispin(false);
    }
  };

  useEffect(() => {
    if (!data || data.length === 0) {
      return;
    }

    const formattedData = data?.map((item) => ({
      name: item.name,
      value1: item.solar,
      value2: item.utility,
    }));

    const chart = echarts?.init(chartRef?.current);

    const option = {
      title: {
        text: "Energy YTD (MVAh) Per Site",
        textStyle: {
          fontSize: 14,
          padding: [10, 0, 0, 0],
        },
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      legend: {
        // Try 'horizontal'
        orient: "vertical",
        right: 10,
        // top: "bottom",
      },
      xAxis: {
        type: "category",
        data: formattedData?.map((item) => item.name),
        axisTick: {
          alignWithLabel: true,
        },
        name: "Sites",
        axisLabel: {
          padding: 0,
        },
        nameLocation: "middle",
        nameGap: 35,
        nameTextStyle: {
          fontSize: 13, // Adjust font size
          color: "#333", // Change color if needed
          fontFamily: "Arial",
          fontStyle: "italic",
          fill: "#222222",
          stroke: "none",
          strokeWidth: "0",
        },
      },
      yAxis: {
        type: "value",
        axisLabel: {
          show: false,
        },
        name: "Energy YTD (MVAh)",
        nameLocation: "center",
        nameTextStyle: {
          fontSize: 13, // Adjust font size
          fontWeight: "bold", // Make it bold
          color: "#333", // Change color if needed
          fontFamily: "Arial",
          fontStyle: "italic",
          fill: "#222222",
          stroke: "none",
          strokeWidth: "0",
        },
      },
      series: [
        {
          name: "Utility",
          type: "bar",
          data: formattedData?.map((item) => item.value2),
          itemStyle: {
            color: "#d3d3d3",
          },
          stack: "x",
        },
        {
          name: "Solar",
          type: "bar",
          data: formattedData?.map((item) => item.value1),
          itemStyle: {
            color: "#008000",
          },
          stack: "x",
        },
      ],
    };

    chart.setOption(option);
    window.addEventListener("resize", chart.resize);

    return () => {
      window.removeEventListener("resize", chart.resize);
      chart.dispose();
    };
  }, [data]);

  // return (
  //   <div className="renewable-energy-container" style={{ width: "90%" }}>
  //     <div
  //       ref={chartRef}
  //       style={{
  //         width: "100%",
  //         height: "300px",
  //         marginTop: "0px",
  //       }}
  //     />
  //   </div>
  // );

  return (
    <>
      {isSpin ? (
        <Spinner
          style={{ margin: "auto" }}
          color="dark-grey"
          size="small"
        ></Spinner>
      ) : (
        <>
          {data?.length > 0 ? (
            <div
              className="renewable-energy-container"
              style={{ width: "90%" }}
            >
              <div
                ref={chartRef}
                style={{
                  width: "100%",
                  height: "300px",
                  marginTop: "0px",
                }}
              />
            </div>
          ) : (
            <p className="center-text-error"></p>
          )}
        </>
      )}
    </>
  );
}

export default RenewableEnergy;
