import React from 'react';
import {
    NotificationContainer
} from "@abb/abb-common-ux-react";
import { MICROGRID_MESSAGES } from '../../strings';
export default function NotificationExample(props) {

    const { notitype } = props;

    const [notiData, setnotiData] = [{
        notifications: [{
            id: 1,
            type: 'banner',
            discreet: 1,
            severity: "info",
            timeout: 3000,
            text: `Please wait while we open the file in wavewin`,
        }]
    }];
    const [errornotiData, seterrornotiData] = [{
        errornotifications: [{
            id: 2,
            type: 'banner',
            discreet: 2,
            severity: "warn",
            timeout: 3000,
            text: `Error while generating url.`,
        }]
    }];
    const [updatepwd, setupdatepwd] = [{
        notifications: [{
            id: 1,

            type: 'banner',
            discreet: 1,
            severity: "success",
            timeout: 3000,
            text: `Password changed successfully`,
        }]
    }];
    const [networkParamers, setnetworkParamers] = [{
        notifications: [{
            id: 1,
            type: 'banner',
            discreet: 1,
            severity: "success",
            timeout: 3000,
            text: MICROGRID_MESSAGES.NETWORK_FILES_UPLOAD_SUCCESS,
        }]
    }];
    const [objFunctions, setobjFunctions] = [{
        notifications: [{
            id: 1,
            type: 'banner',
            discreet: 1,
            severity: "success",
            timeout: 3000,
            text: MICROGRID_MESSAGES.OBJ_FUN_DATA_SUCCESS,
        }]
    }];
    const [noFilesUpload, setnoFilesUpload] = [{
        notifications: [{
            id: 1,
            type: 'banner',
            discreet: 1,
            severity: "warn",
          
            text: MICROGRID_MESSAGES.NO_FILE_UPLOADED_NOTI,
        }]
    }];
    return (
        <>
            {notitype === "true" && <NotificationContainer notifications={notiData.notifications} style={{ position: 'absolute', top: '100px', right: '0px', zIndex: 9 }} />}
            {notitype === "error" &&
                <NotificationContainer notifications={errornotiData.errornotifications} style={{ position: 'absolute', top: '100px', right: '0px', zIndex: 9 }} />}
            {notitype === "updatePassword" && <NotificationContainer notifications={updatepwd.notifications} style={{ position: 'absolute', top: '50px', right: '0px', zIndex: 9 }} />}
            {/* microfrid-notifications */}
            {notitype === "networkfiles" && <NotificationContainer notifications={networkParamers.notifications} style={{ position: 'absolute', top: '50px', right: '0px', zIndex: 9 }} />}
            {notitype === "objfuntions" && <NotificationContainer notifications={objFunctions.notifications} style={{ position: 'fixed', top: '50px', right: '0px', zIndex: 9 }} />}
            {notitype === "noFilesUpload" && <NotificationContainer notifications={noFilesUpload.notifications} style={{ position: 'absolute', top: '130px', right: '0px', zIndex: 9 }} />}
        </>
    );

}
<NotificationExample />;