import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Datagrid, Icon, Dialog, Button } from "@abb/abb-common-ux-react";
import { RootContext } from "../../../contexts/root-context";
import UserSession from "../../../components/Authentication/user-session";
import ProoferForm from '../../../components/proofer-tool-cmps/popup-form/proofer-form';
import ProoferTabs from '../../../components/proofer-tool-cmps/popup-output-tabs/popup-output-tabs';
import axios from 'axios';
import Navbar from '../../../components/Navbar/navbar';
import "./dashboard.css";
import { Link } from '@abb/abb-common-ux-react';
function isBlank(str) {
    return str === null || str === undefined || (typeof str === 'string' && /^\s*$/.test(str));
}

function TestComp({ i, processStatus }) {
    // localStorage.getItem("current-doc-id",i)
    React.useEffect(() => {
        return () => {
            // Cleanup if needed
        };
    }, []);

    return (
        <>
            {i === "Enquiry Document RFQ 1.pdf"? (
                <Icon name="abb/check-mark" style={{ marginRight: '16px' }} onClick={() => alert(`Status completed on ${i}`)} />
            ) : (
                <Icon name="abb/hour-glass" style={{ marginRight: '16px' }} onClick={() => alert(`Status is pending on  ${i}`)} />
            )}
        </>
    );
}

const MemoizedTestComp = React.memo(TestComp);

function ProoferDashboard() {
    const [showDialog, setShowDialog] = useState(false); // State to control dialog visibility
    const [allDocsData, setAllDocsData] = useState([]);
    const [data, setData] = React.useState([]);
    const { currentProoferState, setcurrentProoferState } = useContext(RootContext);
    const postRequestUrlPrefix = "https://api.dev.demo.cognien.abb/proofer/";
    const getProoferDashboardAllDocs = async () => {
        let requestURL = postRequestUrlPrefix + "doc/all";
        axios.get(requestURL)
            .then((response) => {
                const docs = response.data["documents"];
                // Check if docs is an array, otherwise wrap it into an array
                if (Array.isArray(docs)) {
                    setAllDocsData(docs);
                    setRecordsforDatagrid();
                } else if (docs && typeof docs === 'object') {
                    setAllDocsData([docs]); // Wrap single object into an array
                    setRecordsforDatagrid();
                } else {
                    console.error("Unexpected API response:", docs);
                    setAllDocsData([]); // Set empty array if the response is unexpected
                }
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(() => {

            });
    }
    useEffect(() => {
        // if(currentProoferState == "showtabs") {
        getProoferDashboardAllDocs();
        // }
    }, []);

    

    const showSummary=(fname)=>{
        
        localStorage.setItem("current-doc-id",fname)
        setcurrentProoferState("showtabs")
    }



    const setRecordsforDatagrid = () => {
        // Make sure allDocsData is an array
        if (!Array.isArray(allDocsData)) {
            console.error("allDocsData is not an array:", allDocsData);
            return;
        }

        const transformedData = allDocsData?.map((doc, i) => ({
            rowId: doc.id,
            fields: {
                filename: ( <Link
                    href=""
                    onClick={() => showSummary(doc.id)}
                    
                  >{doc.name}</Link>),
                oppname: doc.metadata.oppno,
                projname: doc.metadata.project,
                custname: doc.metadata.customername,
                prodgroup: doc.metadata.productgroup,
                uploadedat: doc.metadata.createdon,
                state: (
                    <div>
                        <MemoizedTestComp key={`TestComp-${doc.name}`} i={doc.name} processStatus={doc.process_status} />
                    </div>
                ),
            },
            isSelected: i % 4 === 0 ? null : false,
            disableSelect: i % 5 === 0,
            rowStatus: i % 7,
            fieldStatuses: { Value: i % 7 },
        }));

        setData(transformedData);
        setRowCount(transformedData.length);
    };

    function createProoferForm() {
        setShowDialog(true);
        setcurrentProoferState("create-form");
    }


    const initialColumns = React.useMemo(
        () => [
            { fieldKey: 'filename', title: 'File Name' },
            { fieldKey: 'oppname', title: 'Opportunity Name' },
            { fieldKey: 'projname', title: 'Project Name' },
            { fieldKey: 'custname', title: 'Customer Name' },
            { fieldKey: 'prodgroup', title: 'Product Group' },
            { fieldKey: 'uploadedat', title: 'Uploaded At' },

            {
                fieldKey: 'state',
                title: 'state',
                // width: 64,
                // sortFn: (a, b) => {
                //     const valA = a.state.props.children.props.i;
                //     const valB = b.state.props.children.props.i;
                //     return valA < valB ? -1 : valA > valB ? 1 : 0;
                // },
            },
        ],
        []
    );

    const [rowCount, setRowCount] = React.useState(1);
    const [currentPage, setCurrentPage] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(10);

    const [columns, setColumns] = React.useState(initialColumns);
    const [footerStyle, setFooterStyle] = React.useState('columns');
    const [columnedFooterData] = React.useState(
        initialColumns.reduce((acc, c) => {
            acc[c.fieldKey] = `Footer: ${c.title}`;
            return acc;
        }, {})
    );
    const [freeFooterData] = React.useState(
        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
            <span>Some footer content here...</span>
            <span>...and more content here.</span>
        </div>
    );
    const [sortOrder, setSortOrder] = React.useState("desc");
    const [columnOrder, setColumnOrder] = React.useState(undefined);
    const [columnFilters, setColumnFilters] = React.useState({
        // filename: { value: '', placeholder: 'File Name' },
        oppname: { value: '', placeholder: 'Opp name' },
        projname: { value: '', placeholder: 'Project Name' },
        custname: { value: '', placeholder: 'Customer Name' },
        prodgroup: { value: '', placeholder: 'Product group' },

    });
    const [hideColumnFilters, setHideColumnFilters] = React.useState(false);

    const handleColumnFilterChange = (newFilters) => {
        setColumnFilters(newFilters);
        const filteredData = data
            .map((c) => Object.assign({}, c))
            .filter((c) => {
                return (
                    // (isBlank(newFilters.filename.value) || ('' + c.fields.filename).toLowerCase().indexOf(newFilters.filename.value.toLowerCase()) > -1) &&
                    (isBlank(newFilters.oppname.value) || ('' + c.fields.oppname).toLowerCase().indexOf(newFilters.oppname.value.toLowerCase()) > -1) &&
                    (isBlank(newFilters.projname.value) || ('' + c.fields.projname).toLowerCase().indexOf(newFilters.projname.value.toLowerCase()) > -1) &&
                    (isBlank(newFilters.custname.value) || ('' + c.fields.custname).toLowerCase().indexOf(newFilters.custname.value.toLowerCase()) > -1) &&
                    (isBlank(newFilters.prodgroup.value) || ('' + c.fields.prodgroup).toLowerCase().indexOf(newFilters.prodgroup.value.toLowerCase()) > -1)

                );
            });
        setData(filteredData);
        setRowCount(filteredData.length);
        setCurrentPage(0);
    };

    const handleSelectRow = (rowIndex) => {
        setData(
            data.map((d, i) => Object.assign(d, { isSelected: rowIndex === i ? !d.isSelected : d.isSelected }))
        );
    };
  

    return (
        <>
            <UserSession />
            <Navbar />

            {currentProoferState == "showtabs" ? <ProoferTabs /> :
                <>
                    <div className="proofer-header-container">
                        <div className="col-5 col-m-12 col-s-12">
                            <div className="filter-header-proofer">Proofer Dashboard</div>
                        </div>
                        <div className="col-7 col-m-12 col-s-12">
                            {/* {startTS} To {endTS} */}
                            <div className="filters-group row">
                             
                                <Icon id="ic-refresh" sizeClass='large' name="abb/refresh" style={{ marginRight: '16px',marginTop:"5px", color: "white" }} onClick={getProoferDashboardAllDocs} />


                                <Button
                                    text="Generate"
                                    onClick={() => createProoferForm()}
                                    shape="pill"
                                    type="primary-red"
                                />
                                {/* <Button
                                    text="Refresh"
                                    onClick={getProoferDashboardAllDocs}
                                    shape="pill"
                                    type="primary-white"
                                /> */}
                            </div>
                        </div>
                    </div>
                    {showDialog && currentProoferState == "create-form" ? <ProoferForm showDialog={showDialog} setShowDialog={setShowDialog} /> : ""}
                    {currentProoferState == "showtabs" ? <ProoferTabs showDialog={showDialog} setShowDialog={setShowDialog} /> : ""}

                    <Datagrid
                        id='proofer-datagrid'
                        className="ABB_CommonUX_Datagrid__dark"
                        columns={columns}
                        columnFilters={columnFilters}
                        hideColumnFilters={hideColumnFilters}
                        footerStyle={footerStyle}
                        columnedFooterData={columnedFooterData}
                        freeFooterData={freeFooterData}
                        data={data}
                        rowCount={rowCount}
                        onSelectRow={handleSelectRow}
                        sortOrder={sortOrder}
                        enableSorting={true}
                        columnOrder={columnOrder}
                        setSortOrder={setSortOrder}
                        setColumnOrder={setColumnOrder}
                        pageSize={pageSize}
                        currentPage={currentPage}
                        onPageChange={setCurrentPage}
                        onPageSizeChange={setPageSize}
                        hasSelectAll={true}
                        onColumnFiltersChange={handleColumnFilterChange}
                        onClickColumnFiltersIcon={() => setHideColumnFilters(!hideColumnFilters)}
                        onClickClearColumnFilters={() => {
                            const newFilters = Object.assign({}, columnFilters);
                            Object.keys(newFilters).forEach((k) => {
                                newFilters[k].value = '';
                            });
                            handleColumnFilterChange(newFilters);
                            setHideColumnFilters(true);
                        }}
                        customMessage={data.length !== 0 ? undefined : 'Nothing in data, please click refresh button'}

                    /> </>}

        </>

    );
}

export default ProoferDashboard;